<template>
  <VForm
    ref="searchBar"
    v-model="valid"
    lazy-validation
  >
    <VCombobox
      ref="searchBarInput"
      v-model.trim="selectedItem"
      outlined
      filled
      attach
      class="combobox tt-input tt-text-field"
      :loading="loading"
      :items="recentRequests"
      :search-input.sync="search"
      :rules="validationRules"
      clearable
      :allow-overflow="false"
      placeholder="Название уровня, задания или контент"
      :menu-props="{
        closeOnClick : false,
        closeOnContentClick : true,
        disableKeys : false,
        openOnFocus : false,
        openOnClick : false,
        value : showMenu
      }"
      :hide-details="valid"
      data-test="search-bar-input"
      @update:search-input="handleSearchTrim"
      @blur="handleBlur"
      @click="handleFocus"
      @input="handleChangeEmit"
      @keyup.enter="(e) => handleChangeEmit(e.target.value,false,true)"
    >
      <template #append>
        <VIcon @click="handleChangeEmit(search,true,true)">
          $search
        </VIcon>
      </template>
      <template #item="{item}">
        <VListItemContent>
          {{
            item
          }}
        </VListItemContent>
        <VListItemAction
          style="z-index: 5"
          @click.stop
        >
          <VBtn
            icon
            x-small
            @click.stop.prevent="handleDelete(item)"
          >
            <VIcon>
              fal fa-times
            </VIcon>
          </VBtn>
        </VListItemAction>
      </template>
    </VCombobox>
  </VForm>
</template>
<script>
import { R_CONTENT_SEARCH } from '@/plugins/vue-router';

export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateQuery: {
      type: Boolean,
      default: false,
    },
    toSearchPage: {
      type: Boolean,
      default: false,
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valid: true,
      showMenu: false,
      lastSearchResults: [],
      search: '',
      selectedItem: '',
      errorMessage: '',
      error: false,
      rules: [
        (v) => (v && v.length >= 3) || 'Поле должно содержать не менее 3 символов',
      ],
    };
  },
  computed: {
    validationRules() {
      if (this.shouldValidate) {
        return [
          (v) => (v && v.length >= 3) || 'Поле должно содержать не менее 3 символов',
        ];
      }
      return [];
    },
    recentRequests() {
      const requests = [...this.lastSearchResults].reverse();
      if (requests.length > 0) {
        requests.unshift({ header: 'Недавние запросы' });
      }
      if (this.search) {
        return requests.filter((item) => {
          if (item.header) return item;
          return item.includes(this.search);
        });
      }
      return requests;
    },
  },
  beforeDestroy() {
    this.resetValidation();
  },
  mounted() {
    if (this.$route.query.search) {
      this.selectedItem = this.$route.query.search;
    }
    this.updateLastResults();
  },
  methods: {
    handleSearchTrim(value) {
      if (!value) return '';
      this.search = value.trim();
      this.selectedItem = value.trim();
      return value;
    },
    handleFocus() {
      this.resetValidation();
      this.showMenu = true;
    },
    handleBlur() {
      this.$refs.searchBar.validate();
      this.showMenu = false;
    },
    redirectToSearchPage(value) {
      this.$router.push({ name: R_CONTENT_SEARCH, query: { search: value } });
    },
    resetValidation() {
      this.$refs.searchBar.resetValidation();
    },
    handleChangeEmit(value, updateSearchSync = false, closeMenu = false) {
      if (!value) { return; }
      const searchValue = value.trim();
      if (updateSearchSync) {
        this.selectedItem = searchValue;
      }
      if (closeMenu) {
        this.showMenu = false;
      }

      this.$nextTick(() => {
        if (this.shouldValidate) {
          const valid = this.$refs.searchBar.validate();

          if (!valid) return;
        }
        if (this.toSearchPage) {
          this.redirectToSearchPage(searchValue);
        }
        if (this.updateQuery) {
          this.updateQueryParams(searchValue);
        }
        this.updateLastResults();
        this.saveIntoLocalStorage([...this.lastSearchResults, searchValue]);
        this.$emit('change', searchValue);
        this.$refs.searchBarInput.blur();
      });
    },
    handleDelete(value) {
      this.lastSearchResults = this.lastSearchResults.filter((item) => item !== value);
      this.saveIntoLocalStorage(this.lastSearchResults);
    },
    getLastSearchResults() {
      const lastSearched = JSON.parse(localStorage.getItem('last_searched'));
      if (!lastSearched) return [];
      if (lastSearched.length > 10) {
        return lastSearched.slice(-10);
      }
      return lastSearched;
    },
    updateLastResults() {
      this.lastSearchResults = this.getLastSearchResults();
    },
    saveIntoLocalStorage(data) {
      localStorage.setItem('last_searched', JSON.stringify(this.uniqueData(data)));
      this.updateLastResults();
    },
    uniqueData(data) {
      return [...new Set(data)].filter((item) => !!item);
    },
    updateQueryParams(value) {
      if (this.$route.query.search === value) return;
      this.$router.replace({ query: { search: value } });
    },
  },
};
</script>
<style lang="scss">
.combobox{
  &.error--text {
    .v-input__slot {
      border:1px solid #EF323F !important;
    }
  }
  .v-input__append-inner{
    padding-right: 12px;
  }
  .v-select__slot{
    input{
      position: relative!important;
    }
  }
}
.v-autocomplete__content{
  .v-subheader{
    padding:0;
    color: #8F9295;
  }
  .v-list{
    padding:  8px 16px;
    &-item{
      margin: 4px 0;
      padding: 0 8px;
      &:hover, &--highlighted, &--active, &:before{
        border-radius: 4px;
        background-color: #F5F6F6;
      }
      &:not(:last-child){
        &:after {
          position: unset;
          bottom: unset;
          left: unset;
          right: unset;
          content: "";
          width: unset;
          height: unset;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
