var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VForm',{ref:"searchBar",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('VCombobox',{ref:"searchBarInput",staticClass:"combobox tt-input tt-text-field",attrs:{"outlined":"","filled":"","attach":"","loading":_vm.loading,"items":_vm.recentRequests,"search-input":_vm.search,"rules":_vm.validationRules,"clearable":"","allow-overflow":false,"placeholder":"Название уровня, задания или контент","menu-props":{
      closeOnClick : false,
      closeOnContentClick : true,
      disableKeys : false,
      openOnFocus : false,
      openOnClick : false,
      value : _vm.showMenu
    },"hide-details":_vm.valid,"data-test":"search-bar-input"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.handleSearchTrim],"blur":_vm.handleBlur,"click":_vm.handleFocus,"input":_vm.handleChangeEmit,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.handleChangeEmit(e.target.value,false,true); })($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('VIcon',{on:{"click":function($event){return _vm.handleChangeEmit(_vm.search,true,true)}}},[_vm._v(" $search ")])]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('VListItemContent',[_vm._v(" "+_vm._s(item)+" ")]),_c('VListItemAction',{staticStyle:{"z-index":"5"},on:{"click":function($event){$event.stopPropagation();}}},[_c('VBtn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleDelete(item)}}},[_c('VIcon',[_vm._v(" fal fa-times ")])],1)],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedItem"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }